import { useEffect, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import moment from 'moment';

import { LOGIN_USER_JVT } from "graphql/mutation/user-gql";

import { useLogout } from "../use-hooks";
import { errorNotification } from "../result";


const useJwtAuthorization = () => {

    const { search } = useLocation(),
          history  = useHistory(),
          { logout } = useLogout(),
          searchParams = useMemo(() => new URLSearchParams(search), [ search ]);

    const jwtAuthDate = localStorage.getItem("jwtAuth" );

    if( !!jwtAuthDate && moment().diff(jwtAuthDate, "hours") > 12){
        localStorage.setItem("jwtAuth", undefined);
        logout({ redirectRoute: "/" });
    }

    const [ loginRequest, { loading }] = useMutation( LOGIN_USER_JVT,
        {
            update(cache, { data : { loginJWT } }) {

                const { access_token } = loginJWT;

                localStorage.setItem("authToken", access_token);
                localStorage.setItem("jwtAuth", moment().format('YYYY-MM-DD HH:mm'));
                localStorage.setItem("jwtToken", searchParams.get('token'));
                cache.writeData({ data: {authToken : access_token}});

                history.replace({
                    search: "",
                })
            }
        }
    );

    useEffect( () => {
        if(searchParams.get("token")){

            loginRequest({
                variables : {
                    tkn : searchParams.get('token')
                }
            }).catch( errorNotification )
        }
    }, [searchParams, loginRequest]);

    return {
        loading
    }

}

export default  useJwtAuthorization;