import React from 'react';
import { useApolloClient } from "@apollo/react-hooks";

import { GET_ME } from "graphql/query/user-gql";


import { HomeTimelinePosts, NewsTimelinePosts } from "components/posts";
import { UserAvatar } from "components/user";
import SummaryStatistic from "components/summary-statistic";
import { NotificationButton } from "components/notifications";
import { ContainerWrap, TimelineWrap, WrapColumn }  from "components/wraps";
import { ElementVisibility } from "components/service";


import logo from 'assets/desktop_logo.svg';
import Icons from "../../icons";



const HomePage = ({ type }) => {

    const client = useApolloClient();
    const { me } = client.readQuery({ query: GET_ME });

    return (
        <>
            <ElementVisibility maxWidth={ 768 } type="show" comparison="<">
                <ul className="mobile-logo-wrap header-bg-color">
                    <li>
                        <img src={logo} alt="logo"/>
                    </li>
                    <li>
                        <NotificationButton
                            cssClass={ 'header-action-notification' }
                            userId={ me.id }
                        />
                    </li>
                </ul>
            </ElementVisibility>
            { localStorage.getItem("jwtAuth") &&
                <div className="diamond-game-wrap company-bg-color v2">
                    <ContainerWrap>
                        <a
                            href={`https://www.diamantenspiel.de/login?token=${ localStorage.getItem("jwtToken") }`}
                            target="_blank"
                            rel="noreferrer"
                            className="with-icon game"
                        >
                            <Icons.Diamond /> Diamantenspiel <Icons.Diamond />
                        </a>
                    </ContainerWrap>
                </div>
            }
            <div className="statistics-wrap site-bg-color-second">
                <ContainerWrap>
                    <SummaryStatistic />
                </ContainerWrap>
            </div>
            <ContainerWrap className="home-timeline-wrap container-margin">
                <TimelineWrap>
                    <WrapColumn position="left">
                        { type === "news" ?
                            <NewsTimelinePosts />:
                            <HomeTimelinePosts />
                        }
                    </WrapColumn>
                    <WrapColumn position="right">
                        <UserAvatar user={ {...me} }/>
                    </WrapColumn>
                </TimelineWrap>
            </ContainerWrap>
        </>
    );
};

export default HomePage;